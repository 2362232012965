<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    :width="dialogWidth"
    scrollable
  >
    <v-card v-if="showDialog">
      <slot name="title">
        <v-toolbar
          flat
        >
          <v-toolbar-title>
            <span class="px-2 text-h5 font-weight-bold">
              {{ $t('m1201.ReportDialog.header') }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </slot>
      <v-card-title />
      <v-card-subtitle>
        <slot name="subtitle">
          <div class="text-h6">
            <small>
              {{ $t('m1201.ReportDialog.sub_header', { type: $t('m1201.ReportDialog.rel_models.' + relModel) }) }}
            </small>
          </div>
          <div class="text-subtitle-1 mt-3">
            {{ $t('m1201.ReportDialog.subtitle') }}
          </div>
        </slot>
      </v-card-subtitle>
      <v-card-text v-if="!showForm">
        <v-list three-line>
          <v-list-item-group
            v-model="selectedIndex"
            color="primary"
          >
            <template v-for="(c, i) in categories">
              <v-list-item :key="`category-${i}`">
                <v-list-item-action>
                  <v-radio-group v-model="selectedIndex">
                    <v-radio :value="i" />
                  </v-radio-group>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ c.translated_category_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ c.translated_category_desc }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="i < categories.length - 1"
                :key="i"
              />
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-text v-show="showForm">
        <v-form class="py-2">
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-textarea
                v-model="form.user_report_report"
                counter
                maxlength="1000"
                outlined
                :label="$t('m1201.ReportDialog.form.user_report_report')"
              />
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <label class="text-body-1">
                {{ $t('m1201.ReportDialog.form.attachments') }}
              </label>
              <AtFilePicker
                v-model="form.attachments"
                accept="image/*"
                max-files="5"
                max-file-size="5MB"
                @upload-done="uploadDone = $event"
              />
              <span class="text-body-2">
                {{ $t('m1201.ReportDialog.form.attachments_rules') }}
              </span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12">
          <v-btn
            v-if="!showForm"
            :disabled="!form.m0601_id"
            color="primary"
            block
            @click="showForm = true"
          >
            {{ $t('m1201.ReportDialog.next_step') }}
          </v-btn>
          <v-btn
            v-else
            color="secondary"
            block
            @click="showForm = false"
          >
            {{ $t('common.return1') }}
          </v-btn>
          <v-btn
            v-if="showForm"
            class="mt-2"
            :disabled="!uploadDone"
            color="primary"
            block
            @click="submit"
          >
            {{ $t('common.submit') }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      showForm: false,
      form: {},
      selectedIndex: null,
      uploadDone: true
    }
  },
  computed: {
    ...mapGetters('M1201ReportCase', {
      categories: 'getCategories'
    }),
    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100vw'
        case 'sm': return '85vw'
        case 'md': return '70vw'
        case 'lg': return '55vw'
        case 'xl': return '40vw'
        default: return '40vw'
      }
    },
    showDialog: {
      get () {
        this.resetState()
        return this.$store.getters['M1201ReportCase/getShowDialog']
      },
      set (val) {
        if (val) { this.resetState() }
        this.$store.commit('M1201ReportCase/SET_SHOW_DIALOG', val)
      }
    },
    relModel: {
      get () { return this.$store.getters['M1201ReportCase/getRelModel'] },
      set (val) { this.$store.commit('M1201ReportCase/SET_REL_MODEL', val) }
    },
    relId: {
      get () { return this.$store.getters['M1201ReportCase/getRelId'] },
      set (val) { this.$store.commit('M1201ReportCase/SET_REL_ID', val) }
    }
  },
  watch: {
    selectedIndex (index) {
      this.$set(this.form, 'm0601_id', this.categories[index]?.id)
    }
  },
  created () {
  },
  methods: {
    resetState () {
      this.showForm = false
      this.form = {}
      this.selectedIndex = null
    },
    selectCategory (category) {
      this.selectedCategory = category
    },
    submit () {
      const input = {
        ...this.form,
        user_report_rel_model: this.relModel,
        user_report_rel_id: this.relId
      }
      this.storeApiDispatch('M1201ReportCase/submit', input)
        .then(() => {
          this.$dialog.notify.success(this.$t('m1201.ReportDialog.submitted'))
          this.showDialog = false
          this.resetState()
        })
    }
  }
}
</script>
