<template>
  <v-autocomplete
    ref="time"
    v-model="dValue"
    :items="items"
    v-bind="{
      ...{
        outlined: true,
        menuProps: 'auto',
        style: 'width: 130px',
      },
      ...$attrs,
    }"
    v-on="$listeners"
    @update:search-input="onSearchInput"
  />
</template>

<script>
// for meeting use, 15min interval
import m from 'moment'
import momentPlugin from '@/plugins/moment'
const moment = momentPlugin(m)

export default {
  props: {
    value: {
      type: String,
      default: () => moment().round(15, 'minutes').format('HH:mm')
    },
    min: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dValue: this.value,
      items: [],
      dMin: null
    }
  },
  computed: {
    currentFormat () {
      const time = moment(this.dValue, 'HH:mm', true)
      const datetime = moment(this.dValue, 'YYYY-MM-DD HH:mm', true)
      if (datetime.isValid()) {
        return 'YYYY-MM-DD HH:mm'
      } else if (time.isValid()) {
        return 'HH:mm'
      } else {
        // wrong format
        return 'HH:mm'
      }
    }
  },
  watch: {
    value: {
      handler (val) {
        this.dValue = val
      },
      immediate: true,
      deep: true
    },
    dValue: {
      handler (val) {
        this.genItems(val)
        this.$emit('input', val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onSearchInput (val) {
      // if ref time is focused
      if (!this.$refs.time.isFocused) return

      // debounce 500ms
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        // convert hh:mm A to HH:mm:ss
        const time = moment(val, 'hh:mm A', true)
        if (time.isValid()) {
          this.dValue = time.format(this.currentFormat)
        }
      }, 1000)
    },
    genItems (time) {
      // if note valid time, return []
      if (!moment(time, this.currentFormat).isValid()) return []
      // depend on this.dValue, generate past 12 hour and next 12 hour with 15 min steps
      let items = []
      if (this.min) {
        for (let i = 1; i < (24 * (60 / 15)); i++) {
          const next = moment(this.min, this.currentFormat).round(1, 'minutes').add(15 * i, 'minutes')
          items = [
            ...items,
            {
              text: next.format('hh:mm A') + ' (' + moment.duration(15 * i, 'minutes').humanize() + ')',
              value: next.format(this.currentFormat)
            }
          ]
        }
      } else {
        for (let i = 0; i < (12 * (60 / 15)); i++) {
          const prev = moment(time, this.currentFormat).subtract(15 * i, 'minutes')
          const next = moment(time, this.currentFormat).add(15 * i, 'minutes')
          items = [
            {
              text: prev.format('hh:mm A'),
              value: prev.format(this.currentFormat)
            },
            ...items,
            {
              text: next.format('hh:mm A'),
              value: next.format(this.currentFormat)
            }

          ]
        }
      }

      this.items = items
    }
  }
}
</script>

<style>

</style>
